<template>
	<div class="flex-side-mypage mr-30">
		<div class="pa-30-20 radius-20 bg-white">
			<div class="under-line pb-10 flex-row">
				<h3 class="flex-1">마이페이지</h3>
			</div>

			<div>
				<ul>
					<li
						@click="$bus.$emit('to', { name: 'myPage'})"
						:class="{on: on_side == 'mypage'}"
					>
						<div class="box-side-icon mr-10">
							<img
								v-if="on_side == 'mypage'"
								:src="require('@/assets/image/web/mypage_cartel/ico_my01_on.svg')"
							/>
							<img
								v-else
								:src="require('@/assets/image/web/mypage_cartel/ico_my01_off.svg')"
							/>
						</div>
						<div class="flex-1">마이 프로필</div>
					</li>
					<li
						@click="copy"
					>
						<div class="box-side-icon mr-10"><img
							:src="require('@/assets/image/web/mypage_cartel/ico_my02_off.svg')"
						/></div>
						<div class="flex-1 flex-row justify-space-between">
							<div class="cell_tit">회원번호</div>
							<div class="member_number">{{ user.member_number }} <i class="mdi mdi-content-copy" style="color: #333"></i></div>
						</div>
					</li>
					<li
						@click="$bus.$emit('to', { name: 'mafia102-1'})"
						:class="{on: on_side == 'mafia102-1'}"
					>
						<div class="box-side-icon mr-10">
							<img
								v-if="on_side == 'mafia102-1'"
								:src="require('@/assets/image/web/mypage_cartel/ico_my03_on.svg')"
							/>
							<img
								v-else
								:src="require('@/assets/image/web/mypage_cartel/ico_my03_off.svg')"
							/>
						</div>
						<div class="flex-1">
							<div class="cell_tit">친구</div>
						</div>
					</li>

					<li
						@click="$bus.$emit('to', { name: 'mafia105'})"
						:class="{on: on_side == 'mafia105'}"
					>
						<div class="box-side-icon mr-10">
							<img
								v-if="on_side == 'mafia105'"
								:src="require('@/assets/image/web/mypage_cartel/ico_my04_on.svg')"
							/>
							<img
								v-else
								:src="require('@/assets/image/web/mypage_cartel/ico_my04_off.svg')"
							/>
						</div>
						<div class="flex-1">
							<div class="cell_tit">알림</div>
						</div>
					</li>
					<li
						@click="$bus.$emit('to', {name: 'mafiaCredit'}) "
						:class="{on: on_side.indexOf('mafiacredit') > -1}"
					>
						<div class="box-side-icon mr-10">
							<img
								v-if="on_side == 'mafiacredit'"
								:src="require('@/assets/image/web/mypage_cartel/ico_my05_on.svg')"
							/>
							<img
								v-else
								:src="require('@/assets/image/web/mypage_cartel/ico_my05_off.svg')"
							/>
						</div>
						<div class="flex-1">
							<div class="cell_tit">신용카드 관리</div>
						</div>
					</li>
					<li
						@click="$bus.$emit('to', {name: 'mafia111'}) "
						:class="{on: on_side == 'mafia111'}"
					>
						<div class="box-side-icon mr-10">
							<img
								v-if="on_side == 'mafia111'"
								:src="require('@/assets/image/web/mypage_cartel/ico_my05_on.svg')"
							/>
							<img
								v-else
								:src="require('@/assets/image/web/mypage_cartel/ico_my05_off.svg')"
							/>
						</div>
						<div class="flex-1">
							<div class="cell_tit">개인정보 보호</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mypage-side'
		, props: ['program', 'user']
		, data: function(){
			return {

			}
		}
		, computed:{
			on_side: function(){
				let t = (this.$route.name).toLowerCase()

				return t
			}
		}
		, methods: {
			copy: function (){
				this.clipBoard(this.user.member_number);
				this.$bus.$emit('notify',  { type: 'success', message: '회원번호가가 복사되었습니다'})
			}
			,clipBoard: function (val){
				const t = document.createElement("textarea");
				document.body.appendChild(t);
				t.value = val;
				t.select();
				document.execCommand('copy');
				document.body.removeChild(t);
			}

		}
		, created() {
		}
	}
</script>

<style>

	.flex-side-mypage li{
		padding: 20px 0;
		border-bottom: 1px solid #ddd;
		color: #888; font-size: 16px; font-weight: 400;
		display: flex; cursor: pointer;
	}
	.flex-side-mypage li.on {
		color: #333;
	}

	.flex-side-mypage .box-side-icon {
		flex-basis: 25px;
	}

	.flex-side-mypage .member_number {
		letter-spacing: 0px; color: #333; margin-top: 2px;
	}
</style>