<template>
  <div>
    <PopupLayer>
      <template
          v-slot:body
      >
        <div
            class="flex-column radius-20 text-left  radius-20 overflow-hidden"
            style="min-width: 320px; max-width: 480px; margin: 0 auto;"
        >
          <div class="bg-primary flex-row justify-space-between items-center pa-10-20">
            <h4 class="color-white">차단된 계정</h4>

            <v-icon
                @click="$emit('cancel')"
                class="color-white"
            >mdi-close-circle
            </v-icon>
          </div>

          <div class="bg-white">

            <div class="pa-10-20">
              <div class="content_wrap mt-20">
                <ul>
                  <li
                      v-for="(item, index) in items_blocked_user"
                      :key="'item_' + index"
                  >
                    <div class="picture">

                      <img
                          v-if="item.profle_nft_card_img_url"
                          :src="$request.upload_url(item.profle_nft_card_img_url)"
                          referrerpolicy="no-referrer"
                      >
                      <img
                          v-else
                          :src="require('@/assets/image/@profile_inner.png')" alt="추천카르텔"
                          referrerpolicy="no-referrer"
                      />
                    </div>
                    <div class="text_area">
                      <strong>{{ item.nickname }}</strong>
                      <p class="textzone textzone">
                        {{ item.state_message }}
                      </p>
                    </div>

                    <div class="ct_invite_btn">
                      <button
                          class="btn-inline radius-30 mr-20"
                          @click="onConfirm(item)"
                          :disabled="false"
                      >차단 해제
                      </button>
                    </div>
                  </li>
                </ul>
                <div
                    v-if="items_blocked_user.length <= 0"
                    class="pa-10 flex-column justify-center items-center" style="transform: translateY(50%);"
                >
                  <div><img :src="require('@/assets/image/list_none.png')" alt="이력없음" style="width: 120px"></div>
                  <div class="mt-20">차단된 사용자가 없습니다.</div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </template>
    </PopupLayer>
    <PopupConfirm
        v-if="is_confirm"
        @click="postBlockCancel"
        @cancel="is_confirm = false"
    >
      <template
          v-slot:title
      >차단 해제</template>
      <template
          v-slot:main-txt
      >차단 해제 하시겠습니까?</template>
      <template
          v-slot:sub-txt
      >차단 해제 하시려면 확인을 눌러주세요.</template>
    </PopupConfirm>
  </div>

</template>
<script>

import PopupLayer from "@/view/Layout/PopupLayer";
import PopupConfirm from "@/view/Layout/PopupConfirm";

export default {
  name: "BlockedAccount",
  components: {PopupLayer, PopupConfirm},
  props: ['user'],
  data() {
    return {
      items_blocked_user: [],
      is_confirm: false,
      item_block: {},
    }
  },

  methods: {
    onConfirm: function (item_block) {
      this.item_block = item_block
      this.is_confirm = true
    },

    getBlockedUserList: async function(){
      try{
        this.$bus.$emit('on', true)
        const result = await this.$request.init({
          method: 'post'
          , url: this.$api_url.api_path.get_black_list
          , data: {
            member_number: this.user.member_number
            , page_number: this.$language.base.page_number
            , pagerecnum: this.$language.base.pagerecnum
          }
          , type: true
        })

        if(result.success){
          this.items_blocked_user = result.data.blok_member_list
          this.$storage.setQuery(this.item_search)
        }else{
          throw result.message
        }

      }catch (e) {
        console.log(e)
        this.$bus.$emit('notify', { type: 'error', message: e})
      }finally {
        this.is_search = true
        this.$bus.$emit('on', false)
      }
    }
    , postBlockCancel: async function(){
      try {
        this.$bus.$emit('on', true)
        const result = await this.$request.init({
          method: 'post'
          , url: this.$api_url.api_path.post_black_cancel
          , data: {
            member_number: this.user.member_number
            , blok_member_number: this.item_block.blok_member_number
          }
          , type: true
        })

        console.log('postFriend result ', result)
        if(result.success){
          this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
          this.$emit('cancel')
          await this.getBlockedUserList()
        }else{
          throw result.message
        }
      }catch (e) {
        console.log(e)
        this.$bus.$emit('notify', { type: 'error', message: e})
      }finally {
        this.is_confirm = false
        this.$bus.$emit('on', false)
      }
    }
  }

  ,async created() {
    await this.getBlockedUserList()
  }
}
</script>

<style scoped>
li {
  display: flex;
  column-gap: 10px;
  height: 80px;
}

.text_area {
  width: 320px;
}

img {
  border-radius: 50%;
}

.btn-inline {
  background-color: white;
  color: var(--blue02);
  border: 2px solid var(--blue02); /* Green */

  width: 95px;
  font-size: 12px;
}

.btn-inline:hover {
  background-color: var(--blue02) !important;
  color: white;
}

.content_wrap {
  height: 350px;
  overflow-y: auto;
}

.picture {
  width: 70px; height: 80px; overflow: hidden; border-radius: 20px;
}
.picture img{
  width: 80%;
  object-fit: cover;
}
</style>