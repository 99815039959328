<template>
	<PopupConfirm
		@click="$emit('click')"
		@cancel="$emit('cancel')"
	>
		<template
			v-slot:title
		>
			<h3 v-if="type == 'out'" class="">MAFIA 탈퇴</h3>
			<h3 v-else class="">MAFIA 로그아웃</h3>
		</template>
		<template
			v-slot:main-txt
		>
			<p v-if="type == 'out'" class="">
				마피아를 탈퇴 하시겠습니까?
				<span>탈퇴된 마피아 계정 및 지갑 정보는 복구할 수 없으니 신중하게 선택하시길 바랍니다.</span>
			</p>
			<p v-else class="">
				로그아웃 하시겠습니까?
			</p>
		</template>

		<template
			v-slot:sub-txt
		></template>
	</PopupConfirm>
</template>

<script>
	import PopupConfirm from "@/view/Layout/PopupConfirm";
	export default {
		name: 'mafia1112'
		, components: {PopupConfirm}
		, props: ['type']
		, data: function(){
			return {

			}
		}
	}
</script>