<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div
				class="width-400 bg-white radius-20 ma-auto"
			>
				<div class="pa-20 under-line text-center">
					<h3 class="pop_tit pop_tit_logo">탈퇴</h3>
				</div>

				<div class="pa-50-20">
					<div class="">
						<div class="square-120 ma-auto">
							<img :src="require('@/assets/image/intro_character_2.png')" alt="마피아" class="width-100">
						</div>
						<div class=" pt-20">
							<h4>마피아 탈퇴 전 꼭 확인해 주세요!</h4>
						</div>
						<div class=" pt-15">
							<p class="text">탈퇴 시 카르텔 내 등록한 작성글은 자동 삭제되지 않습니다. NFT지갑, 코인지갑 내 거래 내역 등의 모든 정보가 삭제됩니다. 탈퇴된 마피아 계정으로는 로그인할 수 없으므로 마피아 서비스에 연동된 모든 지갑은 이용할 수 없게 됩니다.</p><br>
							<p class=" color-red">꼭! 지갑 내 NFT, 코인을<br>외부 거래소 또는 지갑으로 전송하세요.</p><br>
							<p class="text">탈퇴된 마피아 계정 및 지갑 정보는 복구할 수 없으니 신중하게 선택하시길 바랍니다.</p>
						</div>

						<ul class="quit_list mt-25">
							<li
								v-if="true"
							>
								<div class="bright_checks quit_agree">
									<input
											type="checkbox" id="nft3"
											v-model="checkBox"
									>
									<label for="nft3">
										<p>현재 소유한 NFT, 코인이 있으나 탈퇴에 동의합니다. </p>
									</label>
								</div>
							</li>
							<li
									v-else
							>
								<div class="bright_checks quit_agree">
									<input
											type="checkbox" id="nft1"
											v-model="checkBox"
									>
									<label for="nft1">
										<p>현재 소유한 NFT, 코인이 없습니다.</p>
									</label>
								</div>
							</li>

						</ul>

						<div class="pt-30 text-center">
							<button
								class="btn-inline btn-primary radius-20 mr-10"
								@click="() => {
									if (checkBox) {
										is_1112 = true
									} else {
										$bus.$emit('notify', { type: 'error', message: '동의항목을 확인해주세요.'})
									}
								}"
							>탈퇴하기 </button>
							<button
								class="btn-inline btn-dark-gray radius-20"
								@click="$emit('cancel')"
							>취소 </button>
						</div>
					</div>
				</div>
			</div>

			<mafia1112
				v-if="is_1112"
				type="out"
				@cancel="is_1112 = !is_1112"
				@click="postOut"

			></mafia1112>
		</template>
	</PopupLayer>

</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
import mafia1112 from "@/view/Mypage/mafia111-2"

export default {
	name: "Withdrawal",
	components: {PopupLayer, mafia1112},
	props: ['user'],
	data() {
		return {
			is_1112: false,
			member_social_certfc_code : '',
			checkBox: false,
		}
	},

	created() {
		let login_info = this.$encodeStorage.getItem('login_info')
		if(login_info){

			switch (login_info.type){
				case 'kakao':
					this.member_social_certfc_code = 'MB00400001'
					break
				case 'google':
					this.member_social_certfc_code = 'MB00400003'
					break
				case 'apple':
					this.member_social_certfc_code = 'MB00400002'
					break
				default:
					return false
			}
		}
	},

	methods: {
		postOut: async function(){

			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_member_out
					, data: {
						member_number: this.user.member_number
						, member_social_certfc_code: this.member_social_certfc_code
						//, social_certfc_code: e.access_token
					}
					, type: true
				})
				if(result.success){
					await this.$encodeStorage.removeSessionAt()
					await this.$encodeStorage.removeItem('login_info')
					document.location.href = '/'
				}else{
					this.$bus.$emit('notify',	{ type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
				this.is_1112 = false
			}
		}
	}
}
</script>