<template>

	<div
		class="mt-30 text-left flex-row"
	>
		<MypageSide
			:program="program"
			:user="user"
		></MypageSide>

		<div
			class="flex-1 bg-white pa-30 radius-20"
		>
			<div>
				<div class="under-line pb-10 flex-row">
					<h3 class="flex-1">{{ program.title }}</h3>
				</div>
				<div class="container">
					<div class="subindex_box_1">
						<span class="cell_group_tit">제한</span>
						<ul class="settings_list">
							<li class="settings_list_item">
								<div class="cell_tit">활동정보 공개</div>
								<button
									@click="is_confirm_activity = true"
								>
									<div class="cell_area">
										<div class="cell_action"><span class="text mr-10">{{ is_active }}</span><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li class="settings_list_item">
                <div class="cell_tit">차단된 계정</div>
<!--                <button
                    @click="$emit('to', { name: 'mafia112' })"
                >-->
								<button
									@click="blocked_user_popup = true"
								>
									<div class="cell_area">
										<div class="cell_action"><span class="text mr-10"> {{ item_user.blok_member_count }}</span><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="subindex_item">
				<div class="container">
					<div class="subindex_box_1">
						<span class="cell_group_tit">인증번호</span>
						<ul class="settings_list">
							<li class="settings_list_item">
								<div class="cell_tit">PIN 번호 변경</div>
<!--								<button @click="onPin('check')">-->
								<button @click="is_pin = true">
									<div class="cell_area">
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li class="settings_list_item">
								<div class="cell_tit">Google OTP</div>
								<button @click="$emit('to', {name: 'mafia1113'})">
									<div class="cell_area">
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="subindex_item">
				<div class="container">
					<div class="subindex_box_1">
						<span class="cell_group_tit">계정</span>
						<ul class="settings_list">
							<li class="settings_list_item">
								<div class="cell_tit">로그아웃</div>
								<button @click="type = 'logout'; is_1112 = true">
									<div class="cell_area">
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li class="settings_list_item">
								<div class="cell_tit">탈퇴</div>
								<button @click="withdrawal_popup = true">
									<div class="cell_area">
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<mafia1112
			v-if="is_1112"
			:type="type"

			@cancel="is_1112 = !is_1112"
			@click="postLogout"
		></mafia1112>

		<PopupConfirm
			v-if="is_confirm_activity"
			@click="postCartelActivity(true)"
			@cancel="postCartelActivity(false)"
		>
			<template v-slot:title>활동정보 공개</template>
			<template
				v-slot:main-txt
			>공개여부를 선택해 주세요.</template>
			<template
				v-slot:sub-txt
			>활동정보 비공개 시 친구들이 내 프로필에서 해당 카르텔 가입 여부를 확인 할 수 없습니다.</template>
			<template
				v-slot:name-cancel
			>비공개</template>
			<template
				v-slot:name-confirm
			>공개</template>
		</PopupConfirm>

    <BlockedAccount
        v-if="blocked_user_popup"
        :user="user"

        @cancel="blocked_user_popup = false; getData()"
    ></BlockedAccount>

    <Withdrawal
        v-if="withdrawal_popup"
        :user="user"
        @cancel="withdrawal_popup = false"
	></Withdrawal>

    <Pin
        v-if="is_pin"
        @close="() => {
          this.is_pin = false
          this.pin_type = 'check'
        }"
        :user="user"
        :pin_type="pin_type"
        @changePinType="changePinType"
    ></Pin>

	</div>
</template>

<script>

import mafia1112 from "@/view/Mypage/mafia111-2"
import PopupConfirm from "@/view/Layout/PopupConfirm";
import MypageSide from "@/view/Mypage/mypage-lnb";
import BlockedAccount from "@/view/Mypage/popup/BlockedAccount";
import Withdrawal from "@/view/Mypage/popup/Withdrawal";
import Pin from "@/view/Mypage/popup/Pin";

export default {
	name: 'mafia111'
	, components: {PopupConfirm, mafia1112, MypageSide, BlockedAccount, Withdrawal, Pin}
	, props: ['Axios', 'callBack', 'user']
	, data: function(){
		return {
			program: {
				name: '개인정보 보호'
				, title: '개인정보 보호'
				, not_footer: true
				, type: 'mypage'
				, from: 'myPage'
				, callBack: {
					name: 'pin_check'
				}
				, bg_contents: 'bg-gray01'
			}
			, is_pin: false
			, is_1112: false
			, type: 'logout'
			, is_confirm_activity: false
      , is_confirm_withdrawal : false
			, item_user: {}
			, is_otp: false
      , blocked_user_popup: false
      , withdrawal_popup: false
      , pin_type : 'check'
		}
	}
	, computed: {
		is_active: function(){
			let t = '비공개'

			if(this.item_user.activity_info_release_fg == 'Y'){
				t = '공개'
			}

			return t
		}
	}
	,methods: {
		getData: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_my_info
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})
				if(result.success){
					this.item_user = result.data
				}else{
					throw 'not info'
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		,logout: async function(){
			this.$bus.$emit('logout')
		}

		,postLogout: async function(){
			console.log('postLogout')
			try {
				this.$bus.$emit('on', true)

				await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_logout
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
				await this.logout()
			}
		}

		,postCartelActivity: async function(is_active){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_activity
					, data: {
						member_number: this.user.member_number
						, activity_info_release_fg: is_active ? 'Y' : 'N'
					}
					, type: true
				})
				if(result.success){
					this.$set(this.item_user, 'activity_info_release_fg', is_active ? 'Y' : 'N')
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
				console.log(e)
			}finally {
				this.is_confirm_activity = false
				this.$bus.$emit('on', false)
			}
		}
		, onPinCallback: function(){
			this.$bus.$on('pinCallback', (pin_type) => {
				switch (pin_type){
					case 'check':
						this.onPin('update')
						break
					case 'update':
						this.offPinCallback()
						this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
						break
				}
			})
		}
		, offPinCallback: function(){
			this.$bus.$on('pinCallback', () => {
				console.log('pincallback off')
			})
		}
		, onPin: function(pin_type = 'check'){
			this.$bus.$emit('onPin', pin_type)
			this.onPinCallback(pin_type)
		}
		, onOtp: function(){
			this.$bus.$emit('onOtp', 'pin_confirm')
		}
		, postOtp: async function(otp){
			try {
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_google_change_otp
					, data: {
						member_number: this.user.member_number
						, gogle_otp_certfc_number: otp
					}
				})

				if(result.success){
					this.onPin('update')
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, updatePin: function(){
			this.is_pin = false
			this.pin_option.pin_type = 'check'
			this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
		}

    ,changePinType(type) {
      this.pin_type = type
    }


	}
	, async created() {
		await this.getData()
		await this.$emit('onLoad', this.program)
	}
	,watch: {
	}
}
</script>
<style>
.cell_group_tit { padding: 1rem 0; display: block; font-size: 1.1rem; color: var(--gray02); line-height: 1.4rem; }
.settings_list_item { position: relative; width: 100%; display: flex; line-height: 4rem; background-color: transparent; justify-content: space-between}
.icon {
  background-color: white;
}

.text {
  color: var(--blue01);
}
</style>